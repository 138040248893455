import NitratesReport from './nitrates-report'
import {AureliaReactReportWrapper} from "$pages/reports-react/AureliaReactReportWrapper";

class NitratesReportReactWrapper extends AureliaReactReportWrapper {
  constructor() {
    super(NitratesReport)
  }
}

export default NitratesReportReactWrapper
