import React from 'react';
import { ColumnDefinition } from '$pages/reports-react/column-definition';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  NitratesReportDocument, NitratesReportDownloadDocument,
  NitratesReportDownloadQuery,
  NitratesReportQuery, NitratesReportQueryVariables,
  ReportFormatEnum,
  Reports,
  SortDirection
} from '$typings/graphql-codegen';
import { SortObject, useGenericReport } from '$pages/reports-react/generic-report';
import { ReportGridDataHook } from '$pages/reports-react/generic-report-hooks';
import { ColumnWidth } from '$pages/reports-react/ColumnWidth';

// Shorten and simplify GraphQL types
type ReportResult = NitratesReportQuery
type ReportVariables = NitratesReportQueryVariables
type ReportData = NitratesReportQuery["reports"]["nitratesReport"]["data"][0] 
type ReportDownloadResult = NitratesReportDownloadQuery

enum ColumnKey {
  Alias = 'alias',
  TankLevel = 'tankLevel',
  WaterFlowAverageM3PerHour = 'waterFlowAverageM3PerHour',
  WaterFlowAverageM3PerDay = 'waterFlowAverageM3PerDay',
  WaterTemperatureAverage = 'waterTemperatureAverage',
  DoseLitersPerHour = 'doseLitersPerHour',
  DoseLitersPerDay = 'doseLitersPerDay',
  DoseGapPercentage = 'doseGapPercentage',
  ConsumptionCalculatedLitersMonth = 'consumptionCalculatedLitersMonth',
  ConsumptionRealLitersMonth = 'consumptionRealLitersMonth',
  ConsumptionYearToDate = 'consumptionYearToDate',
  RefillCountsMonth = 'refillCountsMonth',
  RefillCountsYearToDate = 'refillCountsYearToDate',
}

const NitratesReport = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Title: string = t('UI_Reports_NitratesMonthReport')
  const Filename: string = 'nitrates-month-report'
  
  // Used initially when table loads
  const DefaultSortSettings: SortObject = {
    sortColumnKey: ColumnKey.Alias,
    sortDirection: SortDirection.Asc
  }
  
  // Define columns
  const ColumnDefinitions: ColumnDefinition<ReportData>[] = [
    new ColumnDefinition<ReportData>(
      t('UI_Reports_NitratesMonthly_Table_Header_SiteAlias'),
      (row: ReportData) => (<a href={"/sitedetails/" + row.siteId}>{row[ColumnKey.Alias]}</a>) ?? '',
      ColumnKey.Alias,
      ColumnWidth.Common.SiteName
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_Tanklevel'),
      (row: ReportData) => Math.round(row[ColumnKey.TankLevel] ?? 0) ?? '',
      ColumnKey.TankLevel,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_FlowAverageM3PerHour'),
      (row: ReportData) => Math.round(((row[ColumnKey.WaterFlowAverageM3PerHour] ?? 0) + Number.EPSILON) * 100) / 100 ?? '',
      ColumnKey.WaterFlowAverageM3PerHour,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_FlowAverageM3PerDay'),
      (row: ReportData) => Math.round(((row[ColumnKey.WaterFlowAverageM3PerDay] ?? 0) + Number.EPSILON) * 100) / 100 ?? '',
      ColumnKey.WaterFlowAverageM3PerDay,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_TemperatureAverage'),
      (row: ReportData) => Math.round(((row[ColumnKey.WaterTemperatureAverage] ?? 0) + Number.EPSILON) * 100) / 100 ?? '',
      ColumnKey.WaterTemperatureAverage,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_DoseLitersPerHour'),
      (row: ReportData) => Math.round(((row[ColumnKey.DoseLitersPerHour] ?? 0) + Number.EPSILON) * 100) / 100 ?? '',
      ColumnKey.DoseLitersPerHour,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_DoseLitersPerDay'),
      (row: ReportData) => Math.round(((row[ColumnKey.DoseLitersPerDay] ?? 0) + Number.EPSILON) * 100) / 100 ?? '',
      ColumnKey.DoseLitersPerDay,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_DoseGapPercentage'),
      (row: ReportData) => Math.round(row[ColumnKey.DoseGapPercentage] ?? 0) ?? '',
      ColumnKey.DoseGapPercentage,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_ConsumptionCalculatedLitersMonth'),
      (row: ReportData) => Math.round(row[ColumnKey.ConsumptionCalculatedLitersMonth] ?? 0) ?? '',
      ColumnKey.ConsumptionCalculatedLitersMonth,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_ConsumptionRealLitersMonth'),
      (row: ReportData) => Math.round(row[ColumnKey.ConsumptionRealLitersMonth] ?? 0) ?? '',
      ColumnKey.ConsumptionRealLitersMonth,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_ConsumptionYearToDate'),
      (row: ReportData) => Math.round(row[ColumnKey.ConsumptionYearToDate] ?? 0) ?? '',
      ColumnKey.ConsumptionYearToDate,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_RefillCountsMonth'),
      (row: ReportData) => row[ColumnKey.RefillCountsMonth] ?? '',
      ColumnKey.RefillCountsMonth,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData> (
      t('UI_Reports_NitratesMonthly_Table_Header_RefillCountsYearToDate'),
      (row: ReportData) => row[ColumnKey.RefillCountsYearToDate] ?? '',
      ColumnKey.RefillCountsYearToDate,
      ColumnWidth.Generic.Medium
    )
  ]
  
  
  
  function dataSelector(result: ReportResult): ReportData[] {
    return (result?.reports?.nitratesReport?.data ?? []) as ReportData[]
  }
  
  function dataCounter(result: ReportResult): number {
    return dataSelector(result).length
  }
  
  function downloadUrlSelector(result: ReportDownloadResult): string {
    return result?.reports?.nitratesReport?.blobUrl ?? ''
  }
  
  const { GenericReport } = useGenericReport<
    ReportResult,
    ReportVariables,
    ReportData,
    ReportDownloadResult
  >()
  
  function createTitle(data: ReportGridDataHook<ReportData>): JSX.Element {
    const n: number = data?.data?.length ?? 0
    const text: string = n ? `${Title} - ${n} sites` : Title
    return <div>{text}</div>
  }

  return <GenericReport
    titleCallback={createTitle}
    reportEnum={Reports.NitratesReport}
    supportedFormats={[ReportFormatEnum.Excel, ReportFormatEnum.Csv]}
    mainQueryDocument={NitratesReportDocument}
    downloadQueryDocument={NitratesReportDownloadDocument}
    columnDefinitions={ColumnDefinitions}
    dataSelector={dataSelector}
    dataCounter={dataCounter}
    defaultSortSettings={DefaultSortSettings}
    downloadUrlSelector={downloadUrlSelector}
    downloadFilename={Filename}
    allowRunningUnfiltered={false} />
}

export default NitratesReport
